var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      staticClass: "text-center pa-0 mx-auto",
      class: _vm.additionalClass,
      attrs: { absolute: "", color: "white" },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "ma-0",
          class: { "pa-0 transparent": _vm.$vuetify.breakpoint.mdAndUp },
        },
        [
          !_vm.showFullWidth
            ? _c("v-col", { attrs: { cols: "12", lg: "3", md: "3" } })
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass:
                "mx-auto pa-2 mb-6 d-flex flex-column justify-center align-center",
              attrs: {
                cols: "12",
                lg: _vm.showFullWidth ? 12 : 9,
                md: _vm.showFullWidth ? 12 : 9,
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "pa-1", attrs: { "align-self": "center" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "mr-2 grey-darken-2--text font-weight-regular caption",
                    },
                    [_vm._v(_vm._s(_vm.$t("poweredBy")))]
                  ),
                  _c("v-img", {
                    attrs: {
                      height: "auto",
                      src: require("@/assets/images/norfield-logo.svg"),
                      "max-width": "80",
                      "min-width": "60",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }