<template>
  <v-footer
    absolute
    color="white"
    class="text-center pa-0 mx-auto"
    :class="additionalClass"
  >
    <v-row
      :class="{ 'pa-0 transparent': $vuetify.breakpoint.mdAndUp }"
      class="ma-0"
    >
      <v-col v-if="!showFullWidth" cols="12" lg="3" md="3"> </v-col>
      <v-col
        cols="12"
        :lg="showFullWidth ? 12 : 9"
        :md="showFullWidth ? 12 : 9"
        class="mx-auto pa-2 mb-6 d-flex flex-column justify-center align-center"
      >
        <v-row class="pa-1" align-self="center">
          <span class="mr-2 grey-darken-2--text font-weight-regular caption">{{
            $t("poweredBy")
          }}</span>
          <v-img
            height="auto"
            src="@/assets/images/norfield-logo.svg"
            max-width="80"
            min-width="60"
          >
          </v-img>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  props: {
    showFullWidth: {
      type: Boolean,
      default: false,
    },
    marginStyle: {
      type: String,
      default: "",
    },
  },
  computed: {
    additionalClass() {
      let classString = "";
      if (this.showFullWidth) classString = `${classString} transparent`;
      if (this.marginStyle) classString = `${classString} ${this.marginStyle}`;
      return classString;
    },
  },
};
</script>
